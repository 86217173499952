<template>
  <CardToolbox>
    <sdPageHeader title="Uw SVM Aanvraag">

    </sdPageHeader>
  </CardToolbox>
  <Main>
    <a-row v-if="loadingOffer" :gutter="30">
      <a-col :xs="24">
        <div class="spin">
          <a-spin/>
        </div>
      </a-col>
    </a-row>
    <a-row v-visible="!loadingOffer" v-if="loadingOffer || (!loadingOffer && !loadingOfferError)" :gutter="30">

      <a-col :md="12" :xs="24">
        <sdCards headless>
          Geachte {{offer?.relation?.fullName}},<br>
          <br>
          Hartelijk dank voor je offerte aanvraag met kenmerk {{ offer?.id }}. Wij zijn ervan overtuigd dat wij je namens
          {{ offer?.sender?.senderName }} een aantrekkelijk aanbod doen met {{ offer?.product?.name }}.

        </sdCards>


        <sdCards title="Documenten" v-if="offer?.product?.files?.length > 0">
          <a v-for="(item,index) in offer?.product?.files" :key="index" :href="item?.fileURL">
            {{ item?.name }}
          </a>
        </sdCards>
      </a-col>
      <a-col :md="12" :xs="24">
        <div
            id="adobe-dc-view"
            style="width: 100%; box-shadow: 1px 1px 10px 1px #dadada;display: flex;height:90vh;flex-flow: column;"
        />

      </a-col>
    </a-row>
    <a-row  v-if="!loadingOffer && loadingOfferError" :gutter="30">
      <a-col :md="12" :xs="24">
        <sdCards headless>
          Geachte Heer/Mevrouw,<br>
          <br>
          Deze link waarmee je deze pagina probeert te bezoeken is incorrect of mogelijk verlopen. Contacteer de klantenservice voor meer informatie.
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {defineComponent, onMounted, reactive, computed, ref, onBeforeUnmount} from 'vue';
import {Main, CardToolbox} from '../styled';
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {checkCheckBox} from "../../utility/validators";

const Sign = defineComponent({
  name: 'Sign',
  components: {Main, CardToolbox},
  data() {
    return {
      showEditDrawer: false,
    }
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const offer = reactive(computed(() => state.customer.offer));
    const loadingOffer = reactive(computed(() => state.customer.loadingOffer));
    const loadingSignOffer = reactive(computed(() => state.customer.loadingSignOffer));
    const loadingOfferError = reactive(computed(() => state.customer.loadingOfferError));
    const signatureError = ref(false);
    const sigState = reactive({
      count: 0,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(220,220,220)",
      },
      disabled: false
    })

    const approvals = ref([]);

    const signature1 = ref()

    const clearSignature = () => {
      signature1.value.clear()
    }

    const validateSignature = () => {
      signatureError.value = signature1.value.isEmpty()
    }

    const signOffer = async () => {
      signatureError.value= false;
      if(!signature1.value.isEmpty()) {
        await dispatch('signOffer', {
          token: params.token,
          tenant: params.tenant,
          bankAccountNumber: offer.value.relation.bankAccountNumber,
          debtorName: offer.value.relation.debtorName,
          signatureData: signature1.value.save('image/svg+xml'),
        });
      } else {
        signatureError.value= true;
      }
    }

    onBeforeUnmount(() => {
      dispatch('cleanOffer');
    });

    onMounted(async () => {
      await dispatch('cleanOffer');
      await dispatch('getOffer', params);
      const viewerConfig = {
        embedMode: 'SIZED_CONTAINER',
        showPageControls: true,
      }

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        const adobeDCView = new AdobeDC.View({
          clientId: 'xxx',
          divId: 'adobe-dc-view',
        })

        adobeDCView.previewFile({
          content: {
            location: {
              url: `${process.env.VUE_APP_API_ENDPOINT}${params.tenant}/offer/${params.token}/pdf`,
            },
          },
          metaData: {
            /* file name */
            fileName: `Uw rapport.pdf`,
          },
        }, viewerConfig)
      }, 500)

    });

    return {
      offer,
      loadingOffer,
      loadingOfferError,
      sigState,
      clearSignature,
      signature1,
      loadingSignOffer,
      signOffer,
      approvals,
      checkCheckBox,
      signatureError,
      validateSignature,
    };
  },
});

export default Sign;
</script>

<style>

.signature canvas {
  width: 450px;
  height: 200px;
}
.checkbox-group .ant-form-item-control-input {
  min-height: 0px !important;
}
</style>
